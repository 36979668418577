.EasyToAssign {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 2;
  overflow: auto;

  .spots {
    display: flex;
    flex-direction: row;
    overflow-y: auto;

    .spot {
      width: 300px;
      min-width: 200px;

      button {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}
